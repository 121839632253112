.modal-pay, .modal-customer{
	width: 70%;
	max-height: 90vh;
	top: 5% !important;

	@media #{$small-and-down}{
		width: 100%;
		top: 0 !important;
		max-height: 100vh;
	}

}

.modal-pay.modal-fixed-footer{
	height: 90vh;
	.modal-footer{
		border-top: none;
	}
}