.btn-border{
	color: #6A7284;
	border: 1px solid #D8DDEA;
	background-color: transparent !important;

	&:hover{
		background-color: darken(#D8DDEA, 1%) !important;
	}
}

.btn-border-red{
	color: red;
	border: 1px solid red;
	background-color: transparent !important;

	&:hover{
		background-color: darken(#fff, 5%) !important;
	}
}
.btn-border-blue{
	color: $c-primary;
	border: 1px solid $c-primary;
	background-color: transparent !important;

	&:hover{
		background-color: darken(#fff, 5%) !important;
	}
}

.btn-clean{
	&:hover{
		background-color: transparent !important;
	}
}