//bootstrap 4 flexbox
@import "partials/bs4/_flex.scss";
@import "partials/bs4/_breakpoints.scss";

//bootstrap mixins
@import "bootstrap/bootstrap/variables";
@import "bootstrap/bootstrap/mixins";

$c-primary: #0079EA;
$c-accent: #FFC019;
$c-base: #6A7284;



// materialize variables
// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;


@import "partials/helper/typography";
@import "partials/helper/clearfix";
@import "partials/functions/spacing";

@import "partials/base/color";
@import "partials/base/layout";

@import "partials/components/buttons";
@import "partials/components/form";
@import "partials/components/select";
@import "partials/components/border";
@import "partials/components/tabs";
@import "partials/components/timeline";
@import "partials/components/cards";
@import "partials/components/table";
@import "partials/components/nav";
@import "partials/components/icons";
@import "partials/components/modal";
@import "partials/components/search";
@import "partials/components/linkSpanner";
@import "partials/components/pagenav";
@import "partials/components/badge";


@import "partials/pages/order";
@import "partials/pages/login";

//dropzone
.dz-error-mark{
  svg{
    width: 20px;
    height: 20px;
  }
}











