//https://codepen.io/abisz/pen/qaEOEm/

.timeline {
  
  ul {
    padding: 0 ;
    margin: 20px 0 0  0;
    list-style: none;
    position: relative;
    
    &::before {
      content: ' ';
      height: 100%;
      width: 1px;
      background-color: #eee;
      position: absolute;
      top: 0;
      left: 10px;
      z-index: 0;
    }
  }

  li{
    z-index: 1;
    position: relative;

    &:last-child{
      background: #fff;
    }
  }
  
  li div{
    display: inline-block;
    margin: 0 0 1em 0;
    vertical-align: top;
  }
  
  .bullet {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 1;
    margin-right: 1em;

    span{
      text-align: center;
      color: #000;
      display: block;
      width: 100%;
      font-size: 10px;
      line-height: 20px;
      transform: scale(0.8);
    }
    
    &.pink {
      border: 2px solid pink;
    }
    
    &.green {
      border: 2px solid green;
    }
    
    &.orange {
      border: 2px solid orange;
    }
  }
  
  .time {
    font-size: 0.75em;
    padding-top: 0.25em;
    color: #999;
  }
  
  .desc {
    width: calc(100% - 40px);
  }
  
  h3 {
    color: #333333;
    font-size: 1em;
    font-weight: 400;
    margin: 0 0 10px;
  }
  
  h4 {
    margin: 0;
    font-size: 0.7em;
    font-weight: 400;
    color: #333;
  }
  
}