.tabs{
  background: none;
  border-bottom: 2px solid #ECEEF2;
}

@media (min-width: 900px) {

  .tabs {
    // display: -webkit-flex;
    // display: -ms-flexbox;
    // display: flex;
    // 
    > .tab{
      a{
        padding: 0;
      }
      width: 100px !important;
    }

  }
}

.tab-content{
  padding-top: 20px !important;
}

// .tabs .tab a {
//   color: rgba(38, 166, 154, 0.7);
//   /*Custom Text Color*/
// }

// .tabs .tab a:hover {
//   color:#26a69a;
//   /*Custom Color On Hover*/
// }

// .tabs .tab a:focus.active {
//   color:#26a69a;
//   Custom Text Color While Active
//   background-color: rgba(38, 166, 154, 0.2);
//   /*Custom Background Color While Active*/
// }

// .tabs .indicator {
//   background-color:#26a69a;
//   /*Custom Color Of Indicator*/
// }