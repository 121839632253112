.text-right{
	text-align: right;
}
.text-left-xs{
	@media #{$small-and-down}{
		text-align: left;
	}
}

.underline{ text-decoration: underline; }
.bold     { font-weight: bold; }
.regular  { font-weight: normal; }
.italic   { font-style: italic; }

//Line Height
.lh-100 { line-height: 1; }
.lh-125 { line-height: 1.25; }
.lh-150 { line-height: 1.5; }

//Common Font Size
$fz: 12 14 16 18 20 24;

@each $sizeValue in $fz {
	.fz-#{$sizeValue}{
		font-size: #{$sizeValue}px !important;
	}
}
