.border{
  border: 1px solid #eee;
}
.border-top { 
	border-top: 1px solid #e5e5e5; 
}
.border-bottom { 
	border-bottom: 1px solid #e5e5e5; 
}
.bt-0{
  border-top: none !important;
}

.factory-border-1{
  .card{
    border: 2px solid #ffec8e;
  }
}
.factory-border-2{
  .card{
    border: 2px solid #6fc3ff;
  }
}