 .card .card-title{
	font-size: 18px;
	font-weight: 400;
	border-bottom: 1px solid #eee;
	width: 100%;
	padding: 10px 20px;
	color: #6A7284;
}

.card .card-content{
	padding-bottom: 0;
	padding-top: 0;

}