.order-summary{

	padding: 0 40px;

	@media #{$small-and-down}{
		padding: 0 20px;
	}

	&-number{
		@media #{$small-and-down}{
			.chip{
				float: right !important;
				display: inline-block;
			}
		}

	}

	&-contact {
		@media #{$small-and-down}{
			margin: 15px 0;

			i{
				font-size: 18px !important;
			}
		}
	}

	&-side{
		margin-top: -25px;
		float: right;

		@media #{$small-and-down} {
		  float: none;
		  margin: 15px 0;
		  justify-content: flex-start;
		  // transform: scale(0.8);
		  transform-origin: top left;

		  .fz-24{
		  	font-size: 20px !important;
		  }
		}
	}
	
}

.order-tab{
	@media #{$small-and-down}{
		padding: 0;
	}
}

.order-factory-title{

	> div{

		padding: 0 10px;
	}
}

.order-note-list{
	.col{
		position: relative;

		&:hover{
			.action{
				opacity: 1;
			}
		}
		.action{
			opacity: 0;
			position: absolute;
			right: 20px;
			top: 5px;

			@media #{$medium-and-down}{
				opacity: 1;
			}
		}
	}
	.col:last-child{
		 .divider{
			height: 0;
		}
	}

	.note{
		&-date{
			@media #{$medium-and-down}{
				display: block;
				margin: 10px 0;
			}
		}

		&-message{
			padding-right: 60px;
			@media #{$medium-and-down}{
				margin-top: 0;
				margin-left: 0;
				padding-right: 10px;
			}
		}
	}
}

.order-img-list{
	li{
		width: 19%;
		margin-bottom: 1rem;
		margin-right: .5% !important;
		@media #{$medium-and-down}{
			width: 23.5%;
			margin-right: .5% !important;
		}
		@media #{$small-and-down}{
			width: 48%;
			margin-right: 1% !important;
			box-sizing: border-box;
		}
	}
}


.img-container {
	border: 1px solid #DEE1E8;
	background-color: #fff;
	width: 100%;
	padding-top: 74%; /* 4:3 Aspect Ratio */
	position: relative; 

	> img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		max-width: 100%;
		max-height: 100%;
		margin: 0 auto;
	    
	}
	
	.overlay{
		position: absolute;
		z-index: 2;
		bottom: 0;
		right: 0;
		opacity: 0;
		width: 100%;
		text-align: right;
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 10%,rgba(0,0,0,0.4) 90%); 
	}
	&:hover{
		.overlay{
			opacity: 1;
		}
	}
}


// https://codepen.io/ojbravo/pen/YPJpXe

@media #{$small-and-down}{
	.panel-wrapper {
	  position: relative;
	  padding-bottom: 30px;
	}

	.show, .hide {
	  position: absolute;
	  bottom: 1em;
	  z-index: 100;
	  text-align: center;
	  width: 100%;
	}

	.hide {display: none;}
	.show:target {display: none;}
	.show:target ~ .hide {display: block !important;}
	.show:target ~ .panel {
	   max-height: 2000px; 
	}
	.show:target ~ .fade {
	  margin-top: 0;
	  display: none;
	}

	.panel {
	  position: relative;
	  // margin: 2em auto;
	  width: 70%;
	  max-height: 150px;
	  overflow: hidden;
	  transition: max-height .5s ease;
	}
	.fade {
	  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 90%);
	  height: 100px;
	  margin-top: -100px;
	  position: relative;
	  z-index: 1;
	}

}

.order-filter{
	background: #fff;
	border-top: 1px solid #ECEEF2;
	border-left: 1px solid #ECEEF2;
	position: fixed;
	top: 64px;
	right: 0;
	width: 260px;
	height: 100%;
	transition: .3s ease all;

	&.sidenav{
		top: 0;
		transition: inherit;
	}
	

	&-wrapper{
		width: 100%;
		height: calc(100% - 130px);
		padding: 20px;
		overflow: hidden;
		position: relative;
		z-index: 2;

		@media #{$small-and-down}{
			height: 100%;
		}
	}

	&-main{
		padding-right: 260px;
	}

	&-heading{
		padding: 10px 15px 0 30px;
	}

	&-section{
		margin-bottom: 20px;
		padding: 0 15px 10px;
		border-bottom: 1px solid #eceff1;

		&-title{
			margin-bottom: 20px;
			color: #444;
		}

		label{
			margin: 6px 0;
			color: #444444;
		}
	}
}


.main-full{
	padding-right: 0;
}


.period-space{
  width: 300px;
  margin-top: 30px;
  font-size: 16px;

  @media #{$small-and-down}{
    width: 100%;
    font-size: 12px;
    margin-top: -35px;

  }
}


