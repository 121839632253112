.customer-search{

  &-wrapper{
    margin: 0 auto;
    position: relative;
    align-self: center;


  }

  &-input{
    position: relative;
    height: 40px;
    line-height: 40px;
    width: 300px;
    background: #F5F5F5;


    @media #{$small-and-down}{
      width: 100%;

    }

    input[type=text]{
      border-bottom: none;
      height: 40px;
      line-height: 40px;
      width: 100%;
      padding-left: 10px;
      border: 1px solid #eee;
    }

    input[type=text]:focus:not([readonly]){
      border-bottom: none;
      box-shadow: none;
    }
  }

  &-btn{
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    i{
      color:#8B92A2;
      height: 40px;
      line-height: 40px !important;
    }
  }
}