// https://gist.github.com/jacurtis/30da4bf9a6c9b9b5cc0aebac512ca7c9
// comment by hrubysi

$sides: (
"": "",
"t": "top",
"b": "bottom",
"l": "left",
"r": "right",
);

$breakpoints: (
"": "",
"xs": 576px,
"sm": 768pxm,
"md": 992px,
"lg": 1200px,
);

@each $breakName, $breakValue in $breakpoints {
  @each $sideName, $sideValue in $sides {
    @for $i from 0 through 10 {
      
      $property: if($sideName == '', '', -#{$sideValue});
      $space: $i * 10;
      $selector: '';

      @if $breakName != "" {
        $selector: #{$sideName}-#{$breakName}-#{$i};
      } @else {
        $selector: #{$sideName}-#{$i};
      }

      @if $breakName != "" {
        @media (min-width: $breakValue) {
          .m#{$selector} {
            margin#{$property}: #{$space}px !important;
          }
  
          .p#{$selector} {
            padding#{$property}: #{$space}px !important;
          }
        }
      } @else {
        .m#{$selector} {
          margin#{$property}: #{$space}px !important;
        }

        .p#{$selector} {
          padding#{$property}: #{$space}px !important;
        }
      }
    }
  }
}