

$custom-colors: () !default;
$custom-colors: map-merge((
	"primary": $c-primary,
	"accent": $c-accent,
	"base": $c-base,

	"p-light": #81a2e7,
	"gray-thin": #bbb,
	"gray-light": #8d8d8d,
	"333": #333,
	"555": #555,
	"888": #888
), $custom-colors);

// partials/base/_colors.scss 
// bootstrap mixin
@each $color, $value in $custom-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}





$custom-bg: () !default;
$custom-bg: map-merge((
	"s1": #EAEAEA,
	"s2": #FAF5DF,
	"s3": #FAE0E0,
	"s4": #E8EFFC,
	"s5": #D6F5F5,
	"s6": #FDECDF,
	"s7": #D8F2D4
), $custom-bg);




// partials/base/_colors.scss 
// bootstrap mixin
@each $color, $value in $custom-bg {
  @include bg-variant(".bg-#{$color}", $value);
}




.text-white-50 { color: rgba(255, 255, 255, .5); }

.bg-white{
  background: white;
}

.box-shadow { 
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); 
}

.background-round {
    padding: 10px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .18);
}