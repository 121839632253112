.login-brand{
  margin-top: 20%;

  h5{
    font-weight: 300;
    letter-spacing: 2px;
    color: #898989;
    font-size: 22px;
  }
}

.login-btn{
  width: 220px;
  margin: 20% 0;
}
.login-cover{
  background: url('../images/login.jpg') no-repeat;
  background-size: cover;
  padding-top: 70% !important;
}



@media #{$medium-and-down}{
  .login-bg{
    background-image: url('../images/login.jpg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100vw 100vh;
    background-color: #E1E2E6;
  }

}