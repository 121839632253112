.table{
  th, td{
  	padding-left: 10px;
  	padding-right: 10px;
    word-break: keep-all;
    white-space: nowrap;
    min-width: 100px;
  }

  &-thin{
  	th{
  		padding: 10px 8px;
  	}
  	td{
  		padding: 0 8px;
  	}
  }
}

.table-scroll-responsive {
 display: block;
 width: 100%;
 overflow-x: auto;
 -webkit-overflow-scrolling: touch;
}

table.table-customer{
 td{
    height: 80px;
  }
}


.table-print{
  thead{
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
  }
  tbody{
    border-bottom: 1px solid #999;
  }
  tr{
    border: none;
  }
}

table.custom-striped tr{
  border: none;
}
table.custom-striped > tbody > tr:nth-child(4n+1){
  background:rgba(242, 242, 242, 0.5);
}

table.custom-striped > tbody > tr.tr-sub{
  background: #f1f1f1;

   td{
    padding-top: 5px;
    padding-bottom: 5px;

    > div{
      line-height: 30px;
    }
  }
}