
// Input
input.input-noline, select.select-noline, textarea.textarea-noline{
	border-bottom: none !important;
}

.input-label{
  font-size: 12px;
  color: #6A7284;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  vertical-align: middle;
}

.input-field {
  color: #000000;
  font-size: 16px;

  &.text-primary{
    color: $c-primary;
  }
}

.input-static{
	margin-top: 15px;
	margin-bottom: 15px;

	 .input-field{
		margin: 0;
	}

	.input-label{
		padding-right: 15px;
	}
}

.input-icon-wrap{
  position: relative;

  input{
     position: relative;
     z-index: 1;
     width: 100%;

     &.browser-default{
      background: transparent;
      border: 1px solid #D2D4D8;
      line-height: 2.2rem;
      border-radius: 3px;
      padding: 0 5px;

      & + i{
        top: 7px;
        right: 5px;
      }
     }
  }
  i{
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 0;
  }
}

.fill-minus{
	input, textarea{
		color: red;
	}
	.select-wrapper .caret{
		fill: red;
	}
}

.fill-add{
	input, textarea{
		color: $c-primary;
	}
	.select-wrapper .caret{
		fill: $c-primary;
	}
}



.flex-input{
  > .input-label{
    display: inline-block;
    min-width: 65px;
  }
}
@media #{$small-and-down}{
  .flex-input{

      display: flex;
      > .input-label{
        min-width: 65px;
      }
      > .input-field{}
  }
}

// Select
.select-wrapper input.select-dropdown{
  min-width: 100px;
}
.select-custom{
  background: #E5E7EB;
}

.select-custom input.select-dropdown{
  max-width: 90px;
  border-bottom: none;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
  color: #49525D;

  &:focus{
    border-bottom: 0;
  }
}

