
.navbar nav{
  background: #fff;
}

.navbar-fixed, .navbar-fixed nav{
  top: 0;
  position: fixed;
  

  @media #{$medium-and-down}{
    box-shadow: 1px 1px 10px #aaa;
  }
}
nav .sidenav-trigger{
  color: #bbb;
}

.header-search{

  &-wrapper{
    margin: 0 auto;
    width: 340px;
    position: relative;
    align-self: center;
  }

  &-left{
    background: #E5E7EB;
    width: 120px;
    height: 40px;
    

    .select-wrapper input.select-dropdown{
      border-bottom: none;
      padding-left: 10px;
      height: 40px;
      line-height: 40px;
      color: #49525D;
    }

    .select-wrapper .caret{
      fill:  #49525D;
    }
  }

  &-input{
    position: relative;
    height: 40px;
    line-height: 40px;
    width: 250px;
    padding-left: 10px;
    background: #F5F5F5;

    input[type=text]:not(.browser-default){
      border-bottom: none;
      height: 40px;
      line-height: 40px;
      width: 100%;
    }

    input[type=text]:not(.browser-default):focus:not([readonly]){
      border-bottom: none;
      box-shadow: none;
    }
  }

  &-btn{
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    i{
      color:#8B92A2;
      height: 40px;
      line-height: 40px !important;
    }
  }
}

.navbar-user{
  align-self: center;
  i{
    float: right;
    line-height: unset !important;
    height: auto !important;
  }
} 

.navbar-shadow{
  box-shadow: 1px 6px 4px rgba(0,0,0,0.1);
}

.navbar-search-mob{
  height: 60px;
  padding: 0 10px;
  background: #fff;

  .header-search-wrapper{
    width: 100%;
  }

  .header-search-input{
    width: 100%;
  }

}

.sidenav-main{
  width: 60%;
  position: fixed;
  top: 0;
  left: 0;
  @media only screen and (min-width: 993px){
    width: 200px;
    top: 60px;
    transform: none;
    box-shadow: none;
    background: none;
  }
}
.sidenav-list{
  >li>a {
      color: rgba(0,0,0,0.87);
      display: block;
      font-size: 14px;
      font-weight: 500;
      height: 58px;
      line-height: 58px;
      padding: 0 16px 0 50px;
      border-left: 5px solid transparent;

      &:hover{
        background: rgba(#4981FD, 0.05);
      }

      i.material-icons {
          float: left;
          height: 58px;
          line-height: 58px;
          margin: 0 24px 0 0;
          width: 24px;
          color: rgba(0,0,0,0.54);
      } 
  }

  a.active{
    border-left: 5px solid #4981FD;
  }

  .collapsible-body >ul>li>a{
    padding-left: 80px;
  }
}
.sidenav .collapsible-body{
  background: none;
}
.sidenav li>a>i.material-icons{
  margin-right: 15px;
}
.sidenav-trigger-placer{
  position: relative;
  z-index: 1;
}

