*{
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  padding-top: 63px;
  background: #F8F9FA;
  color: $c-base;
}

main{
  padding-top: 10px;
}
@media only screen and (min-width: 993px){
  #main{
    padding-left: 200px;
  }
}
    
@media only screen and (min-width: 993px){
  .container{
    width: 100%;
    max-width: calc(100vw - 20%);
  }
}
.container{
	@media #{$small-and-down}{
		width: 100%;
	}
}

.page-header{
  border-bottom: 2px solid #ECEEF2;
  height: 70px;
  line-height: 70px;
  font-size: 20px;
  color: #8D94A3;
  display: flex;
  align-items: center;

  a{
    color: #8D94A3;
    height: 70px;
    line-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
}

.page-content{
	padding-left: 3rem;
	padding-right: 3rem;

	@media #{$small-and-down} {
	  padding-left: 0;
	  padding-right: 0;
	}
}

ul.list-inline{
	> li{
		display: inline-block;
		margin-right: .5rem;
	}
}

